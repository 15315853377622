import { NewsItem } from 'interfaces/News/News';
import { useRouter } from 'next/router';
import { useMemo } from 'react';

const news = [
  {
    previewImage: 'veb/veb-slider1-1.jpeg',
    id: 'veb',
    link: 'veb-tour-2024',
    date: [10, 3, 2024],
    filters: ['tournaments', 'var'],
    isHiddenEn: true,
    lang: ['ru'],
  },
  {
    previewImage: 'aeroflot/aeroflot-slider1-1.jpg',
    id: 'aeroflot',
    link: 'aeroflot-tour-2024',
    date: [10, 3, 2024],
    filters: ['tournaments', 'var'],
    lang: ['ru', 'en'],
  },
  {
    previewImage: 'mongolia/mongolia-slider1-1.jpg',
    id: 'mongolia',
    link: 'mongolia-tour-2024',
    date: [10, 1, 2024],
    filters: ['tournaments', 'var'],
    lang: ['ru', 'en'],
  },
  {
    previewImageLocale: {
      ru: 'tournament18/main-image-ru.jpg',
      en: 'tournament18/main-image-en.jpg',
    },
    id: 'tournament18',
    link: 'idchess-tournament18',
    date: [9, 12, 2023],
    filters: ['tournaments', 'var'],
    lang: ['ru', 'en'],
  },
  {
    previewImageLocale: {
      ru: 'webinar/main-image-ru.jpg',
      en: 'webinar/main-image-en.jpg',
    },
    id: 'webinar',
    link: 'idchess-webinar',
    date: [30, 11, 2023],
    filters: ['about'],
    lang: ['ru', 'en'],
  },
  {
    previewImage: 'italy/main-image.jpg',
    id: 'italy',
    link: 'italy-var-seminar',
    date: [23, 11, 2023],
    filters: ['var'],
    lang: ['ru', 'en'],
  },
  {
    previewImage: 'deaf/main-image.jpg',
    id: 'deaf',
    link: 'chess-federation-of-the-deaf',
    date: [5, 9, 2023],
    filters: ['tournaments'],
    lang: ['ru', 'en'],
  },
  {
    previewImage: 'issykkul/main-image.jpg',
    id: 'issykkul',
    link: 'issyk-kul-open-2023',
    date: [22, 7, 2023],
    filters: ['tournaments', 'var'],
    lang: ['ru', 'en'],
  },
  {
    previewImage: 'uzbekistan/main-image.jpg',
    id: 'uzbekistan',
    link: 'championship-in-uzbekistan',
    date: [21, 7, 2023],
    filters: ['tournaments', 'var'],
    lang: ['ru', 'en'],
  },
  {
    previewImage: 'kyrgyzstan/main-image.jpg',
    id: 'kyrgyzstan',
    link: 'championship-in-kyrgyzstan',
    date: [10, 7, 2023],
    filters: ['tournaments', 'var'],
    lang: ['ru', 'en'],
  },
  {
    previewImage: 'fideVar/main-image.jpg',
    id: 'fideVar',
    link: 'fide-about-var',
    date: [6, 7, 2023],
    filters: ['var'],
    lang: ['ru', 'en'],
  },
  {
    previewImage: 'var/main-image.jpg',
    id: 'var',
    link: 'var-in-chess',
    date: [3, 7, 2023],
    filters: ['tournaments', 'var'],
    lang: ['ru', 'en'],
  },
  {
    previewImage: 'finchess/main-image.jpg',
    id: 'finchess',
    link: 'finchess-2023',
    date: [30, 6, 2023],
    filters: ['tournaments'],
    lang: ['ru', 'en'],
  },
  {
    previewImage: 'georgia/main-image.jpg',
    id: 'georgia',
    link: 'championship-in-georgia',
    date: [12, 6, 2023],
    filters: ['tournaments'],
    lang: ['ru', 'en'],
  },
  {
    previewImage: 'sriLanka/main-image.jpg',
    id: 'sriLanka',
    link: 'dhamso-sri-lanka',
    date: [4, 6, 2023],
    filters: ['tournaments'],
    lang: ['ru', 'en'],
  },
  {
    previewImage: 'botvinnik/main-image.jpg',
    id: 'botvinnik',
    link: 'record-at-the-botvinnik-cup',
    date: [16, 5, 2023],
    filters: ['tournaments'],
    lang: ['ru', 'en'],
  },
  {
    previewImage: 'aprilChampionship/main-image.jpg',
    id: 'aprilChampionship',
    link: 'april-russia-championship',
    date: [5, 5, 2023],
    filters: ['tournaments'],
    lang: ['ru', 'en'],
  },
  {
    previewImage: 'bologan/main-image.jpg',
    id: 'bologan',
    link: 'viorel-bologan-about-idchess',
    date: [17, 4, 2023],
    filters: ['tournaments', 'about'],
    lang: ['ru', 'en'],
  },
  {
    previewImage: 'astana/main-image.jpg',
    id: 'astana',
    link: 'tournament-astana',
    date: [16, 4, 2023],
    filters: ['tournaments'],
    lang: ['ru', 'en'],
  },
  {
    previewImage: 'dubov/main-image.jpg',
    id: 'dubov',
    link: 'daniil-dubov-sessions',
    date: [16, 4, 2023],
    filters: ['tournaments'],
    lang: ['ru', 'en'],
  },
  {
    previewImage: 'iraq_tournament/1.jpg',
    id: 'iraq_tournament',
    link: 'iraq-tournament-2024',
    date: [18, 1, 2024],
    filters: ['tournaments', 'var'],
    lang: ['ru', 'en'],
  },
  {
    previewImage: 'usa_tournaments/1.jpg',
    id: 'usa_tournaments',
    link: 'rockville-chess-club-tournaments',
    date: [26, 1, 2024],
    filters: ['tournaments'],
    lang: ['ru', 'en'],
  },
  {
    previewImage: 'azerbaijan_tournaments/1.jpg',
    id: 'azerbaijan_tournaments',
    link: 'azerbaijan-tournaments-2024',
    date: [5, 3, 2024],
    filters: ['tournaments', 'var'],
    lang: ['ru', 'en'],
  },
  {
    previewImage: 'serbia/serbia-slider-1.jpg',
    id: 'serbia',
    link: 'serbia-womens-tournament',
    date: [10, 3, 2024],
    filters: ['tournaments', 'var'],
    lang: ['ru', 'en'],
  },
  {
    previewImage: 'oman/oman-1.jpg',
    id: 'oman',
    link: 'oman-memorandum',
    date: [8, 3, 2024],
    filters: ['tournaments', 'var'],
    lang: ['ru', 'en'],
  },
  {
    previewImageLocale: {
      ru: 'time_control/time_control-main-image-ru.jpg',
      en: 'time_control/time_control-main-image-en.jpg',
    },
    id: 'time_control',
    link: 'idchess-time-control',
    date: [27, 2, 2024],
    filters: ['about'],
    lang: ['ru', 'en'],
  },
  {
    previewImageLocale: {
      ru: 'against_computer/against_computer-main-image-ru.jpg',
      en: 'against_computer/against_computer-main-image-en.jpg',
    },
    id: 'against_computer',
    link: 'playing-against-computer',
    date: [13, 3, 2024],
    filters: ['about'],
    lang: ['ru', 'en'],
  },
  {
    previewImage: 'moscow/moscow-main-image.jpg',
    id: 'moscow',
    link: 'chess-cup-moscow',
    date: [26, 3, 2024],
    filters: ['tournaments', 'var'],
    lang: ['ru', 'en'],
  },
  {
    previewImage: 'croatia/croatia-main-photo.jpg',
    id: 'croatia',
    link: 'croatia-tournaments',
    date: [15, 5, 2024],
    filters: ['tournaments', 'var'],
    lang: ['ru', 'en'],
  },
  {
    previewImage: 'everest/everest-main-image.jpg',
    id: 'everest',
    link: 'everest-tournament',
    date: [10, 5, 2024],
    filters: ['tournaments'],
    lang: ['ru', 'en'],
  },
  {
    previewImage: 'big_chess/big_chess-main-image.jpg',
    id: 'big_chess',
    link: 'big-chess-tournament',
    date: [20, 5, 2024],
    filters: ['tournaments'],
    lang: ['ru', 'en'],
  },
  {
    previewImage: 'mexico/mexico-main-image.jpg',
    id: 'mexico',
    link: 'mexico-tournament',
    date: [17, 5, 2024],
    filters: ['tournaments', 'var'],
    lang: ['ru', 'en'],
  },
  {
    previewImage: 'veterans/veterans-main-image.jpg',
    id: 'veterans',
    link: 'veterans-rapid-tournament',
    date: [27, 5, 2024],
    filters: ['tournaments'],
    lang: ['ru'],
  },
  {
    previewImage: 'four_countries/four_countries-main-image.jpg',
    id: 'four_countries',
    link: 'tournaments-in-four-countries',
    date: [30, 5, 2024],
    filters: ['tournaments'],
    lang: ['ru', 'en'],
  },
];

export const sortedNews = news => {
  if (!news?.length) {
    return [];
  }

  return news.sort((prev, next) => {
    if (
      !prev?.date ||
      !next?.date ||
      !(prev.date?.length >= 3 && next.date?.length >= 3)
    ) {
      return 0;
    }

    const prevDate = new Date(prev.date[2], prev.date[1], prev.date[0]);
    const nextDate = new Date(next.date[2], next.date[1], next.date[0]);

    if (nextDate > prevDate) {
      return 1;
    }

    if (nextDate < prevDate) {
      return -1;
    }

    return 0;
  });
};

export const newsFilters = ['var', 'tournaments', 'about'];

const newsContent = {
  common: {
    var: [
      {
        type: 'image',
        image: 'var/first-image.jpg',
        alt: 'news.items.var.title',
      },
      {
        type: 'subtitle',
        textWithLink: [
          [
            {
              text: 'news.items.var.subtitle.0',
            },
            {
              text: 'idChess',
              link: '/',
            },
            {
              text: 'news.items.var.subtitle.1',
            },
          ],
        ],
      },
      {
        type: 'image',
        image: 'var/image-1.jpg',
        alt: 'news.items.var.title',
      },
      {
        type: 'h2',
        title: 'news.items.var.secondary_title.0',
      },
      {
        type: 'text',
        text: [
          'news.items.var.text.0',
          'news.items.var.text.1',
          'news.items.var.text.2',
          'news.items.var.text.3',
        ],
      },
      {
        type: 'h2',
        title: 'news.items.var.secondary_title.1',
      },
      {
        type: 'text',
        textWithLink: [
          [
            {
              text: 'news.items.var.text.4',
              link: '/',
            },
            {
              text: 'news.items.var.text.5',
            },
          ],
          [
            {
              text: 'news.items.var.text.6',
            },
            {
              text: 'news.items.var.text.7',
              link: 'http://live.idChess.com',
            },
            {
              text: 'news.items.var.text.8',
            },
          ],
        ],
      },
      {
        type: 'slider',
        images: [
          'var/slider-1.jpg',
          'var/slider-2.jpg',
          'var/slider-3.jpg',
          'var/slider-4.jpg',
          'var/slider-5.jpg',
          'var/slider-6.jpg',
          'var/slider-7.jpg',
        ],
      },
      {
        type: 'list',
        title: 'news.items.var.list.title',
        list: [
          {
            name: 'news.items.var.list.items.0.name',
            items: [
              'news.items.var.list.items.0.points.0',
              'news.items.var.list.items.0.points.1',
              'news.items.var.list.items.0.points.2',
            ],
          },
          {
            name: 'news.items.var.list.items.1.name',
            items: ['news.items.var.list.items.1.points.0'],
          },
          {
            name: 'news.items.var.list.items.2.name',
            items: ['news.items.var.list.items.2.points.0'],
          },
        ],
      },
      {
        type: 'text',
        text: ['news.items.var.text.9'],
      },
    ],
    georgia: [
      {
        type: 'image',
        image: 'georgia/first-image.jpg',
        alt: 'news.items.georgia.title',
      },
      {
        type: 'subtitle',
        textWithLink: [
          [
            {
              text: 'news.items.georgia.subtitle',
            },
            {
              text: 'live.idchess.com',
              link: 'https://live.idchess.com/',
            },
          ],
        ],
      },
      {
        type: 'text',
        text: ['news.items.georgia.text.0'],
      },
      {
        type: 'slider',
        images: [
          'georgia/slider-1.jpg',
          'georgia/slider-2.jpg',
          'georgia/slider-3.jpg',
          'georgia/slider-4.jpg',
        ],
      },
      {
        type: 'quote',
        text: ['news.items.georgia.quote.text'],
        name: 'news.items.georgia.quote.name',
        position: 'news.items.georgia.quote.position',
      },
      {
        type: 'results',
        title: 'news.items.georgia.results.0.title',
        innerTitle: 'news.items.georgia.results.0.innerTitle',
        results: [
          {
            text: 'news.items.georgia.results.0.tournaments.0.name',
            link: 'https://live.idchess.com/app/show/tournament/eyJpZCI6MTY2MiwicGFzc3dvcmQiOm51bGx9?tour=eyJpZCI6ODkxOCwicGFzc3dvcmQiOm51bGx9',
          },
          {
            text: 'news.items.georgia.results.0.tournaments.1.name',
            link: 'https://live.idchess.com/app/show/tournament/eyJpZCI6MTY2MSwicGFzc3dvcmQiOm51bGx9?tour=eyJpZCI6ODkxNywicGFzc3dvcmQiOm51bGx9',
          },
          {
            text: 'news.items.georgia.results.0.tournaments.2.name',
            link: 'https://live.idchess.com/app/show/tournament/eyJpZCI6MTY2NiwicGFzc3dvcmQiOm51bGx9?tour=eyJpZCI6ODkyNiwicGFzc3dvcmQiOm51bGx9',
          },
          {
            text: 'news.items.georgia.results.0.tournaments.3.name',
            link: 'https://live.idchess.com/app/show/tournament/eyJpZCI6MTY2NSwicGFzc3dvcmQiOm51bGx9?tour=eyJpZCI6ODkyNSwicGFzc3dvcmQiOm51bGx9',
          },
          {
            text: 'news.items.georgia.results.0.tournaments.4.name',
            link: 'https://live.idchess.com/app/show/tournament/eyJpZCI6MTY2MywicGFzc3dvcmQiOm51bGx9?tour=eyJpZCI6ODkyMywicGFzc3dvcmQiOm51bGx9',
          },
          {
            text: 'news.items.georgia.results.0.tournaments.5.name',
            link: 'https://live.idchess.com/app/show/tournament/eyJpZCI6MTY2NCwicGFzc3dvcmQiOm51bGx9?tour=eyJpZCI6ODkyNCwicGFzc3dvcmQiOm51bGx9',
          },
          {
            text: 'news.items.georgia.results.0.tournaments.6.name',
            link: 'https://live.idchess.com/app/show/tournament/eyJpZCI6MTY1OSwicGFzc3dvcmQiOm51bGx9?tour=eyJpZCI6ODkxNSwicGFzc3dvcmQiOm51bGx9',
          },
          {
            text: 'news.items.georgia.results.0.tournaments.7.name',
            link: 'https://live.idchess.com/app/show/tournament/eyJpZCI6MTY2MCwicGFzc3dvcmQiOm51bGx9?tour=eyJpZCI6ODkxNiwicGFzc3dvcmQiOm51bGx9',
          },
        ],
      },
      {
        type: 'text',
        text: ['news.items.georgia.text.1', 'news.items.georgia.text.2'],
      },
      {
        type: 'image',
        image: 'georgia/image-1.jpg',
        alt: 'news.items.georgia.text.1',
      },
      {
        type: 'results',
        title: 'news.items.georgia.results.1.title',
        innerTitle: 'news.items.georgia.results.1.innerTitle',
        results: [
          {
            text: 'news.items.georgia.results.1.tournaments.0.name',
            link: 'https://live.idchess.com/app/show/tournament/eyJpZCI6MTYxOCwicGFzc3dvcmQiOm51bGx9?tour=eyJpZCI6ODYyMiwicGFzc3dvcmQiOm51bGx9',
          },
          {
            text: 'news.items.georgia.results.1.tournaments.1.name',
            link: 'https://live.idchess.com/app/show/tournament/eyJpZCI6MTYxNiwicGFzc3dvcmQiOm51bGx9?tour=eyJpZCI6ODYxOSwicGFzc3dvcmQiOm51bGx9',
          },
          {
            text: 'news.items.georgia.results.1.tournaments.2.name',
            link: 'https://live.idchess.com/app/show/tournament/eyJpZCI6MTYyMSwicGFzc3dvcmQiOm51bGx9?tour=eyJpZCI6ODYyNSwicGFzc3dvcmQiOm51bGx9',
          },
          {
            text: 'news.items.georgia.results.1.tournaments.3.name',
            link: 'https://live.idchess.com/app/show/tournament/eyJpZCI6MTYyMiwicGFzc3dvcmQiOm51bGx9?tour=eyJpZCI6ODYyNiwicGFzc3dvcmQiOm51bGx9',
          },
          {
            text: 'news.items.georgia.results.1.tournaments.4.name',
            link: 'https://live.idchess.com/app/show/tournament/eyJpZCI6MTYzMCwicGFzc3dvcmQiOm51bGx9?tour=eyJpZCI6ODY0MiwicGFzc3dvcmQiOm51bGx9',
          },
          {
            text: 'news.items.georgia.results.1.tournaments.5.name',
            link: 'https://live.idchess.com/app/show/tournament/eyJpZCI6MTYyOCwicGFzc3dvcmQiOm51bGx9?tour=eyJpZCI6ODYzMywicGFzc3dvcmQiOm51bGx9',
          },
        ],
      },
      {
        type: 'text',
        text: [
          'news.items.georgia.text.3',
          'news.items.georgia.text.4',
          'news.items.georgia.text.5',
          'news.items.georgia.text.6',
        ],
      },
      {
        type: 'image',
        image: 'georgia/image-2.jpg',
        alt: 'news.items.georgia.imageText',
        text: ['news.items.georgia.imageText'],
      },
    ],
    sriLanka: [
      {
        type: 'image',
        image: 'sriLanka/first-image.jpg',
        alt: 'news.items.sriLanka.title',
      },
      {
        type: 'subtitle',
        text: ['news.items.sriLanka.subtitle'],
      },
      {
        type: 'text',
        textWithLink: [
          [
            {
              text: 'news.items.sriLanka.text.0',
            },
            {
              text: 'live.idchess.com',
              link: 'https://live.idchess.com/',
            },
          ],
        ],
      },
      {
        type: 'slider',
        images: [
          'sriLanka/slider-1.jpg',
          'sriLanka/slider-2.jpg',
          'sriLanka/slider-3.jpg',
          'sriLanka/slider-4.jpg',
          'sriLanka/slider-5.jpg',
        ],
      },
      {
        type: 'quote',
        text: ['news.items.sriLanka.quote.text'],
        name: 'news.items.sriLanka.quote.name',
        position: 'news.items.sriLanka.quote.position',
      },
      {
        type: 'text',
        text: ['news.items.sriLanka.text.1', 'news.items.sriLanka.text.2'],
      },
      {
        type: 'photosBy',
        text: ['news.items.sriLanka.photoBy'],
      },
    ],
    botvinnik: [
      {
        type: 'image',
        image: 'botvinnik/first-image.jpg',
        alt: 'news.items.botvinnik.title',
      },
      {
        type: 'subtitle',
        text: ['news.items.botvinnik.subtitle'],
      },
      {
        type: 'text',
        text: ['news.items.botvinnik.text.0'],
      },
      {
        type: 'results',
        title: 'news.items.botvinnik.results.title',
        line: true,
        results: [
          {
            text: 'news.items.botvinnik.results.tournaments.0.name',
            link: 'https://live.ruchess.ru/app/show/tournament/eyJpZCI6MTMzMiwicGFzc3dvcmQiOm51bGx9?tour=eyJpZCI6NzYyNSwicGFzc3dvcmQiOm51bGx9',
            rating: 'news.items.botvinnik.results.tournaments.0.rating',
          },
          {
            text: 'news.items.botvinnik.results.tournaments.1.name',
            link: 'https://live.ruchess.ru/app/show/tournament/eyJpZCI6MTMzMywicGFzc3dvcmQiOm51bGx9?tour=eyJpZCI6NzYyNCwicGFzc3dvcmQiOm51bGx9',
            rating: 'news.items.botvinnik.results.tournaments.1.rating',
          },
          {
            text: 'news.items.botvinnik.results.tournaments.2.name',
            link: 'https://live.ruchess.ru/app/show/tournament/eyJpZCI6MTMzNCwicGFzc3dvcmQiOm51bGx9?tour=eyJpZCI6NzYyMywicGFzc3dvcmQiOm51bGx9',
            rating: 'news.items.botvinnik.results.tournaments.2.rating',
          },
        ],
      },
      {
        type: 'text',
        text: ['news.items.botvinnik.text.1'],
      },
      {
        type: 'slider',
        images: [
          'botvinnik/slider-1.jpg',
          'botvinnik/slider-2.jpg',
          'botvinnik/slider-3.jpg',
          'botvinnik/slider-4.jpg',
          'botvinnik/slider-5.jpg',
          'botvinnik/slider-6.jpg',
          'botvinnik/slider-7.jpg',
          'botvinnik/slider-8.jpg',
          'botvinnik/slider-9.jpg',
        ],
      },
      {
        type: 'photosBy',
        text: ['news.items.botvinnik.photoBy'],
      },
    ],
    aprilChampionship: [
      {
        type: 'image',
        image: 'aprilChampionship/first-image.jpg',
        alt: 'news.items.aprilChampionship.title',
      },
      {
        type: 'subtitle',
        textWithLink: [
          [
            {
              text: 'news.items.aprilChampionship.subtitle',
            },
            {
              text: 'live.ruchess.ru',
              link: 'https://live.ruchess.ru/app/show/organizator_info/106',
            },
          ],
        ],
      },
      {
        type: 'text',
        textWithLink: [
          [
            {
              text: 'news.items.aprilChampionship.text.0',
            },
            {
              text: 'news.items.aprilChampionship.text.1',
              link: 'https://www.ruchess.ru/persons_of_day/kobalia_pd/',
            },
            {
              text: 'news.items.aprilChampionship.text.2',
            },
          ],
        ],
      },
      {
        type: 'image',
        image: 'aprilChampionship/second-image.jpg',
        alt: 'news.items.aprilChampionship.imageText',
        text: ['news.items.aprilChampionship.imageText'],
      },
      {
        type: 'slider',
        images: [
          'aprilChampionship/slider-1.jpg',
          'aprilChampionship/slider-2.jpg',
          'aprilChampionship/slider-3.jpg',
          'aprilChampionship/slider-4.jpg',
          'aprilChampionship/slider-5.jpg',
          'aprilChampionship/slider-6.jpg',
          'aprilChampionship/slider-7.jpg',
          'aprilChampionship/slider-8.jpg',
          'aprilChampionship/slider-9.jpg',
        ],
      },
      {
        type: 'results',
        results: [
          {
            text: 'news.items.aprilChampionship.games.0',
            link: 'https://live.ruchess.ru',
          },
        ],
      },
    ],
    bologan: [
      {
        type: 'image',
        image: 'bologan/first-image.jpg',
        alt: 'news.items.bologan.title',
      },
      {
        type: 'subtitle',
        text: ['news.items.bologan.subtitle'],
      },
      {
        type: 'text',
        text: ['news.items.bologan.text.0', 'news.items.bologan.text.1'],
      },
      {
        type: 'slider',
        images: [
          'bologan/first_slider_1.jpg',
          'bologan/first_slider_2.jpg',
          'bologan/first_slider_3.jpg',
          'bologan/first_slider_4.jpg',
          'bologan/first_slider_5.jpg',
        ],
      },
      {
        type: 'text',
        text: ['news.items.bologan.text.2'],
      },
      {
        type: 'results',
        results: [
          {
            text: 'news.items.bologan.games.0',
            link: 'https://live.idchess.com/app/show/tournament/eyJpZCI6MTE2MCwicGFzc3dvcmQiOm51bGx9?tour=eyJpZCI6NjAxMCwicGFzc3dvcmQiOm51bGx9',
          },
        ],
      },
      {
        type: 'quote',
        text: [
          'news.items.bologan.quote.text.0',
          'news.items.bologan.quote.text.1',
        ],
        name: 'news.items.bologan.quote.name',
        position: 'news.items.bologan.quote.position',
        image: 'bologan/bologan.png',
      },
    ],
    astana: [
      {
        type: 'slider',
        images: [
          'astana/first_slider_1.jpg',
          'astana/first_slider_2.jpg',
          'astana/first_slider_3.jpg',
          'astana/first_slider_4.jpg',
        ],
      },
      {
        type: 'subtitle',
        text: ['news.items.astana.subtitle.0', 'news.items.astana.subtitle.1'],
      },
      {
        type: 'text',
        text: ['news.items.astana.text.0'],
      },
      {
        type: 'video',
        text: ['news.items.astana.videoText'],
        video: '/videos/news-astana.mp4',
        image: 'astana/video-cover.jpg',
      },
      {
        type: 'text',
        text: ['news.items.astana.text.1'],
      },
      {
        type: 'image',
        image: 'astana/anand.jpg',
        alt: 'news.items.astana.first_step_image',
        text: ['news.items.astana.first_step_image'],
      },
      {
        type: 'text',
        text: ['news.items.astana.text.2'],
      },
      {
        type: 'quote',
        text: ['news.items.astana.quote.text'],
        name: 'news.items.astana.quote.name',
        position: 'news.items.astana.quote.position',
        image: 'astana/nurlan.png',
      },
      {
        type: 'text',
        text: ['news.items.astana.text.3'],
      },
      {
        type: 'imagesLine',
        images: ['astana/children-1.jpg', 'astana/children-2.jpg'],
      },
      {
        type: 'text',
        text: ['news.items.astana.text.4', 'news.items.astana.text.5'],
      },
      {
        type: 'slider',
        images: [
          'astana/winner1.jpg',
          'astana/winner2.jpg',
          'astana/winner3.jpg',
        ],
      },
      {
        type: 'text',
        text: ['news.items.astana.text.6'],
      },
      {
        type: 'slider',
        images: [
          'astana/winner4.jpg',
          'astana/winner5.jpg',
          'astana/winner6.jpg',
          'astana/winner7.jpg',
        ],
      },
      {
        type: 'report',
        link: 'http://kt.kz/rus/high-tech/novye_tehnologii_v_shahmatah_neyroset_transliruet_match_1377948887.html',
        read: 'news.read',
        name: 'news.items.astana.report',
        image: 'astana/report1.jpg',
      },
      {
        type: 'text',
        text: ['news.items.astana.text.7', 'news.items.astana.text.8'],
      },
      {
        type: 'image',
        image: 'astana/winner8.jpg',
        alt: 'news.items.astana.text.8',
      },
      {
        type: 'report',
        link: 'https://kz.mir24.tv/news/141958',
        read: 'news.watch',
        name: 'news.items.astana.watch',
        image: 'astana/report2.jpg',
      },
    ],
    dubov: [
      {
        type: 'slider',
        images: [
          'dubov/first_slider_1.jpg',
          'dubov/first_slider_2.jpg',
          'dubov/first_slider_3.jpg',
          'dubov/first_slider_4.jpg',
          'dubov/first_slider_5.jpg',
          'dubov/first_slider_6.jpg',
          'dubov/first_slider_7.jpg',
          'dubov/first_slider_8.jpg',
          'dubov/first_slider_9.jpg',
        ],
      },
      {
        type: 'subtitle',
        textWithLink: [
          [
            {
              text: 'news.items.dubov.subtitle',
            },
            {
              text: 'live.idchess.com',
              link: 'https://live.idchess.com/',
            },
            {
              text: 'news.items.dubov.subtitle_after_link',
            },
          ],
        ],
      },
      {
        type: 'text',
        text: ['news.items.dubov.text.0', 'news.items.dubov.text.1'],
      },
      {
        type: 'results',
        results: [
          {
            text: 'news.items.dubov.games.0',
            link: 'https://live.idchess.com/app/show/tournament/eyJpZCI6MTEyNiwicGFzc3dvcmQiOm51bGx9?tour=eyJpZCI6NTkyMSwicGFzc3dvcmQiOm51bGx9',
          },
          {
            text: 'news.items.dubov.games.1',
            link: 'https://live.idchess.com/app/show/tournament/eyJpZCI6MTEzNCwicGFzc3dvcmQiOm51bGx9?tour=eyJpZCI6NTk0MSwicGFzc3dvcmQiOm51bGx9',
          },
        ],
      },
      {
        type: 'text',
        text: ['news.items.dubov.text.2'],
      },
    ],
    finchess: [
      {
        type: 'image',
        image: 'finchess/first-image.jpg',
        alt: 'news.items.finchess.title',
      },
      {
        type: 'subtitle',
        text: ['news.items.finchess.subtitle'],
      },
      {
        type: 'text',
        text: [
          'news.items.finchess.text.0',
          'news.items.finchess.text.1',
          'news.items.finchess.text.2',
        ],
      },
      {
        type: 'results',
        results: [
          {
            text: 'news.items.finchess.results',
            link: 'https://live.idchess.com/app/show/tournament/eyJpZCI6MTc4NCwicGFzc3dvcmQiOm51bGx9?tour=eyJpZCI6OTYzMCwicGFzc3dvcmQiOm51bGx9',
          },
        ],
      },
      {
        type: 'slider',
        images: [
          'finchess/slider-1.jpg',
          'finchess/slider-2.jpg',
          'finchess/slider-3.jpg',
          'finchess/slider-4.jpg',
          'finchess/slider-5.jpg',
          'finchess/slider-6.jpg',
        ],
      },
      {
        type: 'quote',
        text: ['news.items.finchess.quote.text'],
        name: 'news.items.finchess.quote.name',
        position: 'news.items.finchess.quote.position',
      },
      {
        type: 'text',
        text: ['news.items.finchess.text.3'],
      },
    ],
    fideVar: [
      {
        type: 'image',
        image: 'fideVar/first-image.jpg',
        alt: 'news.items.fideVar.title',
      },
      {
        type: 'subtitle',
        textWithLink: [
          [
            {
              text: 'news.items.fideVar.subtitle.0',
            },
            {
              text: 'news.items.fideVar.subtitle.1',
              link: 'https://www.fide.com/news/2491',
            },
            {
              text: 'news.items.fideVar.subtitle.2',
            },
          ],
        ],
      },
      {
        type: 'text',
        text: ['news.items.fideVar.text.0', 'news.items.fideVar.text.1'],
      },
      {
        type: 'article',
        text: ['news.items.fideVar.articles.0.text'],
        link: 'https://www.kommersant.ru/doc/6082790',
        flag: 'fideVar/russia.svg',
        logo: 'fideVar/kommersant.png',
        languages: ['ru'],
      },
      {
        type: 'article',
        text: ['news.items.fideVar.articles.1.text'],
        name: 'news.items.fideVar.articles.1.name',
        link: 'https://www.tv2.no/direkte/0nr51/sportsdognet-live/64a28779572197c82940708a/var-innfores-i-sjakk',
        flag: 'fideVar/norway.svg',
        logo: 'fideVar/tv2.png',
      },
      {
        type: 'article',
        text: ['news.items.fideVar.articles.2.text'],
        name: 'news.items.fideVar.articles.2.name',
        link: 'https://sport.tvp.pl/71019034/system-var-zadebiutowal-w-rywalizacji-szachistow/amp',
        flag: 'fideVar/poland.svg',
        logo: 'fideVar/sport_tv.png',
      },
      {
        type: 'article',
        text: ['news.items.fideVar.articles.3.text'],
        name: 'news.items.fideVar.articles.3.name',
        link: 'https://tass.ru/sport/18174593/amp',
        flag: 'fideVar/russia.svg',
        logo: 'fideVar/tass.png',
      },
      {
        type: 'article',
        text: ['news.items.fideVar.articles.4.text'],
        name: 'news.items.fideVar.articles.4.name',
        link: 'https://eprimefeed.com/sports/where-did-my-boat-go-video-replays-are-introduced-in-chess-much-the-same-as-in-football/327674/',
        flag: 'fideVar/usa.svg',
        logo: 'fideVar/prime_feed.png',
      },
      {
        type: 'article',
        text: ['news.items.fideVar.articles.5.text'],
        name: 'news.items.fideVar.articles.5.name',
        link: 'https://www.segabg.com/category-sport/vuvezhdat-var-i-shahmata',
        flag: 'fideVar/bulgaria.svg',
        logo: 'fideVar/sega.png',
      },
      {
        type: 'article',
        text: ['news.items.fideVar.articles.6.text'],
        name: 'news.items.fideVar.articles.6.name',
        link: 'http://www.musavat.biz/ru/mobile/news/sistema-var-poyavitsya-i-v-shahmatah_988625.html?d=1',
        flag: 'fideVar/azerbaijan.svg',
      },
      {
        type: 'article',
        text: ['news.items.fideVar.articles.7.text'],
        name: 'news.items.fideVar.articles.7.name',
        link: 'https://mail.kz/ru/news/sport/fide-nachinaet-zapusk-sistemy-var-na-svoih-sorevnovaniyah',
        flag: 'fideVar/kazakhstan.svg',
        logo: 'fideVar/mail_kz.png',
      },
      {
        type: 'article',
        text: ['news.items.fideVar.articles.8.text'],
        name: 'news.items.fideVar.articles.8.name',
        link: 'http://kg.mirtv.ru/news/144595',
        flag: 'fideVar/kyrgyzstan.svg',
        logo: 'fideVar/mir.png',
      },
    ],
    kyrgyzstan: [
      {
        type: 'slider',
        images: [
          'kyrgyzstan/first_slider-1.jpg',
          'kyrgyzstan/first_slider-2.jpg',
          'kyrgyzstan/first_slider-3.jpg',
          'kyrgyzstan/first_slider-4.jpg',
          'kyrgyzstan/first_slider-5.jpg',
          'kyrgyzstan/first_slider-6.jpg',
        ],
      },
      {
        type: 'subtitle',
        text: ['news.items.kyrgyzstan.subtitle'],
      },
      {
        type: 'text',
        text: ['news.items.kyrgyzstan.text.0'],
      },
      {
        type: 'results',
        title: 'news.items.kyrgyzstan.results.title',
        line: true,
        results: [
          {
            text: 'news.items.kyrgyzstan.results.items.0.name',
            rating: 'news.items.kyrgyzstan.results.items.0.text',
          },
          {
            text: 'news.items.kyrgyzstan.results.items.1.name',
            rating: 'news.items.kyrgyzstan.results.items.1.text',
          },
        ],
      },
      {
        type: 'text',
        text: ['news.items.kyrgyzstan.text.1'],
      },
      {
        type: 'slider',
        images: [
          'kyrgyzstan/second_slider-1.jpg',
          'kyrgyzstan/second_slider-2.jpg',
          'kyrgyzstan/second_slider-3.jpg',
          'kyrgyzstan/second_slider-4.jpg',
          'kyrgyzstan/second_slider-5.jpg',
        ],
      },
      {
        type: 'text',
        textWithLink: [
          [
            {
              text: 'news.items.kyrgyzstan.text.2',
            },
            {
              text: 'news.items.kyrgyzstan.text.3',
              link: 'https://www.fide.com/news/2491',
            },
            {
              text: 'news.items.kyrgyzstan.text.4',
            },
          ],
        ],
      },
      {
        type: 'image',
        image: 'kyrgyzstan/inner-image.jpg',
        alt: 'news.items.kyrgyzstan.title',
      },
      {
        type: 'quote',
        text: ['news.items.kyrgyzstan.quote.text'],
        name: 'news.items.kyrgyzstan.quote.name',
        position: 'news.items.kyrgyzstan.quote.position',
        image: 'kyrgyzstan/Isakov.png',
      },
    ],
    uzbekistan: [
      {
        type: 'slider',
        images: [
          'uzbekistan/slider-1.jpg',
          'uzbekistan/slider-2.jpg',
          'uzbekistan/slider-3.jpg',
          'uzbekistan/slider-4.jpg',
          'uzbekistan/slider-5.jpg',
          'uzbekistan/slider-6.jpg',
        ],
      },
      {
        type: 'subtitle',
        text: ['news.items.uzbekistan.subtitle'],
      },
      {
        type: 'text',
        text: ['news.items.uzbekistan.text'],
      },
      {
        type: 'image',
        image: 'uzbekistan/second-image.jpg',
        alt: 'news.items.uzbekistan.title',
      },
      {
        type: 'results',
        line: true,
        results: [
          {
            text: 'news.items.uzbekistan.reviews.0.text',
            quote: 'news.items.uzbekistan.reviews.0.quote',
          },
          {
            text: 'news.items.uzbekistan.reviews.1.text',
            quote: 'news.items.uzbekistan.reviews.1.quote',
          },
          {
            text: 'news.items.uzbekistan.reviews.2.text',
            quote: 'news.items.uzbekistan.reviews.2.quote',
          },
          {
            text: 'news.items.uzbekistan.reviews.3.text',
            quote: 'news.items.uzbekistan.reviews.3.quote',
          },
        ],
      },
      {
        type: 'h2',
        title: 'news.items.uzbekistan.records.titleGlobal',
      },
      {
        type: 'results',
        title: 'news.items.uzbekistan.records.title_1',
        isRegular: true,
        results: [
          {
            text: 'news.items.uzbekistan.records.items_1.0',
            link: 'https://live.idchess.com/app/show/tournament/eyJpZCI6MTk0NywicGFzc3dvcmQiOm51bGx9?tour=eyJpZCI6MTA0MjUsInBhc3N3b3JkIjpudWxsfQ==',
          },
          {
            text: 'news.items.uzbekistan.records.items_1.1',
            link: 'https://live.idchess.com/app/show/tournament/eyJpZCI6MTk0OSwicGFzc3dvcmQiOm51bGx9?tour=eyJpZCI6MTA0MjcsInBhc3N3b3JkIjpudWxsfQ==',
          },
          {
            text: 'news.items.uzbekistan.records.items_1.2',
            link: 'https://live.idchess.com/app/show/tournament/eyJpZCI6MTk0NiwicGFzc3dvcmQiOm51bGx9?tour=eyJpZCI6MTA0MjQsInBhc3N3b3JkIjpudWxsfQ==',
          },
          {
            text: 'news.items.uzbekistan.records.items_1.3',
            link: 'https://live.idchess.com/app/show/tournament/eyJpZCI6MTk0OCwicGFzc3dvcmQiOm51bGx9?tour=eyJpZCI6MTA0MjYsInBhc3N3b3JkIjpudWxsfQ==',
          },
        ],
      },
      {
        type: 'results',
        title: 'news.items.uzbekistan.records.title_2',
        isRegular: true,
        results: [
          {
            text: 'news.items.uzbekistan.records.items_2.0',
            link: 'https://live.idchess.com/app/show/tournament/eyJpZCI6MTkwOCwicGFzc3dvcmQiOm51bGx9?tour=eyJpZCI6MTAxOTMsInBhc3N3b3JkIjpudWxsfQ==',
          },
          {
            text: 'news.items.uzbekistan.records.items_2.1',
            link: 'https://live.idchess.com/app/show/tournament/eyJpZCI6MTkxMCwicGFzc3dvcmQiOm51bGx9?tour=eyJpZCI6MTAxNTAsInBhc3N3b3JkIjpudWxsfQ==',
          },
          {
            text: 'news.items.uzbekistan.records.items_2.2',
            link: 'https://live.idchess.com/app/show/tournament/eyJpZCI6MTkwNywicGFzc3dvcmQiOm51bGx9?tour=eyJpZCI6MTAxNDcsInBhc3N3b3JkIjpudWxsfQ==',
          },
          {
            text: 'news.items.uzbekistan.records.items_2.3',
            link: 'https://live.idchess.com/app/show/tournament/eyJpZCI6MTkwOSwicGFzc3dvcmQiOm51bGx9?tour=eyJpZCI6MTAxNDksInBhc3N3b3JkIjpudWxsfQ==',
          },
        ],
      },
      {
        type: 'results',
        title: 'news.items.uzbekistan.records.title_3',
        isRegular: true,
        results: [
          {
            text: 'news.items.uzbekistan.records.items_3.0',
            link: 'https://live.idchess.com/app/show/tournament/eyJpZCI6MTkxOCwicGFzc3dvcmQiOm51bGx9?tour=eyJpZCI6MTAyMDMsInBhc3N3b3JkIjpudWxsfQ==',
          },
          {
            text: 'news.items.uzbekistan.records.items_3.1',
            link: 'https://live.idchess.com/app/show/tournament/eyJpZCI6MTkyMCwicGFzc3dvcmQiOm51bGx9?tour=eyJpZCI6MTAyMDUsInBhc3N3b3JkIjpudWxsfQ==',
          },
          {
            text: 'news.items.uzbekistan.records.items_3.2',
            link: 'https://live.idchess.com/app/show/tournament/eyJpZCI6MTkzMywicGFzc3dvcmQiOm51bGx9?tour=eyJpZCI6MTAzOTAsInBhc3N3b3JkIjpudWxsfQ==',
          },
          {
            text: 'news.items.uzbekistan.records.items_3.3',
            link: 'https://live.idchess.com/app/show/tournament/eyJpZCI6MTkxNywicGFzc3dvcmQiOm51bGx9?tour=eyJpZCI6MTAyMDIsInBhc3N3b3JkIjpudWxsfQ==',
          },
          {
            text: 'news.items.uzbekistan.records.items_3.4',
            link: 'https://live.idchess.com/app/show/tournament/eyJpZCI6MTkxOSwicGFzc3dvcmQiOm51bGx9?tour=eyJpZCI6MTAyMDQsInBhc3N3b3JkIjpudWxsfQ==',
          },
          {
            text: 'news.items.uzbekistan.records.items_3.5',
            link: 'https://live.idchess.com/app/show/tournament/eyJpZCI6MTkzMiwicGFzc3dvcmQiOm51bGx9?tour=eyJpZCI6MTAzODksInBhc3N3b3JkIjpudWxsfQ==',
          },
        ],
      },
      {
        type: 'results',
        innerTitle: 'news.items.uzbekistan.results.title',
        results: [
          {
            text_paragraphs: [
              'news.items.uzbekistan.results.text.0',
              'news.items.uzbekistan.results.text.1',
              'news.items.uzbekistan.results.text.2',
              'news.items.uzbekistan.results.text.3',
            ],
          },
        ],
      },
      {
        type: 'photosBy',
        text: ['news.items.uzbekistan.photos_by'],
      },
    ],
    issykkul: [
      {
        type: 'slider',
        images: [
          'issykkul/slider-1.jpg',
          'issykkul/slider-2.jpg',
          'issykkul/slider-3.jpg',
          'issykkul/slider-4.jpg',
          'issykkul/slider-5.jpg',
        ],
      },
      {
        type: 'subtitle',
        text: ['news.items.issykkul.subtitle'],
      },
      {
        type: 'text',
        text: ['news.items.issykkul.text'],
      },
      {
        type: 'results',
        title: 'news.items.issykkul.records.title',
        results: [
          {
            text: 'news.items.issykkul.records.items.0',
            link: 'https://live.idchess.com/app/show/tournament/eyJpZCI6MTg5OCwicGFzc3dvcmQiOm51bGx9?tour=eyJpZCI6MTAxMTgsInBhc3N3b3JkIjpudWxsfQ==',
          },
          {
            text: 'news.items.issykkul.records.items.1',
            link: 'https://live.idchess.com/app/show/tournament/eyJpZCI6MTg5OSwicGFzc3dvcmQiOm51bGx9?tour=eyJpZCI6MTAxMTksInBhc3N3b3JkIjpudWxsfQ==',
          },
        ],
      },
      {
        type: 'results',
        innerTitle: 'news.items.issykkul.results.title',
        results: [
          {
            text_paragraphs: [
              'news.items.issykkul.results.text.0',
              'news.items.issykkul.results.text.1',
              'news.items.issykkul.results.text.2',
            ],
          },
        ],
      },
      {
        type: 'photosBy',
        text: ['news.items.issykkul.photos_by'],
      },
    ],
    deaf: [
      {
        type: 'image',
        image: 'deaf/first-image.jpg',
        alt: 'news.items.deaf.title',
      },
      {
        type: 'subtitle',
        text: ['news.items.deaf.subtitle'],
      },
      {
        type: 'results',
        innerTitleWithLink: [
          [
            {
              text: 'news.items.deaf.tournament.title',
            },
            {
              text: 'live.idchess.com',
              link: 'https://live.idchess.com/',
            },
          ],
        ],
        isBold: true,
        results: [
          {
            text_paragraphs: ['news.items.deaf.tournament.text'],
          },
          {
            text: 'news.items.deaf.tournament.man',
            link: 'https://live.idchess.com/app/show/tournament/eyJpZCI6MjE1MSwicGFzc3dvcmQiOm51bGx9?tour=eyJpZCI6MTIwNTQsInBhc3N3b3JkIjpudWxsfQ==',
          },
          {
            text: 'news.items.deaf.tournament.woman',
            link: 'https://live.idchess.com/app/show/tournament/eyJpZCI6MjE1MiwicGFzc3dvcmQiOm51bGx9?tour=eyJpZCI6MTIwNTUsInBhc3N3b3JkIjpudWxsfQ==',
          },
        ],
      },
      {
        type: 'text',
        text: ['news.items.deaf.text.0', 'news.items.deaf.text.1'],
      },
    ],
    italy: [
      {
        type: 'image',
        image: 'italy/first-image.jpg',
        alt: 'news.items.italy.title',
      },
      {
        type: 'subtitle',
        text: ['news.items.italy.subtitle'],
      },
      {
        type: 'text',
        text: ['news.items.italy.text.0'],
      },
      {
        type: 'quote',
        text: ['news.items.italy.reviews.0.text'],
        name: 'news.items.italy.reviews.0.name',
        position: 'news.items.italy.reviews.0.position',
      },
      {
        type: 'imagesLine',
        images: ['italy/image-1.jpg', 'italy/image-2.jpg'],
      },
      {
        type: 'text',
        textWithLink: [
          [
            {
              text: 'news.items.italy.text.1',
            },
            {
              text: 'news.items.italy.text.2',
              link: 'https://live.idchess.com/app/main',
            },
            {
              text: 'news.items.italy.text.3',
            },
          ],
        ],
      },
      {
        type: 'quote',
        text: ['news.items.italy.reviews.1.text'],
        name: 'news.items.italy.reviews.1.name',
        position: 'news.items.italy.reviews.1.position',
      },
      {
        type: 'text',
        textWithLink: [
          [
            {
              text: 'news.items.italy.text.4',
            },
            {
              text: 'news.items.italy.text.5',
              link: 'https://www.fide.com/news/2491',
            },
            {
              text: 'news.items.italy.text.6',
            },
          ],
        ],
      },
      {
        type: 'quote',
        text: ['news.items.italy.reviews.2.text'],
        name: 'news.items.italy.reviews.2.name',
        position: 'news.items.italy.reviews.2.position',
      },
    ],
    webinar: [
      {
        type: 'image',
        alt: 'news.items.webinar.title',
        imageLocale: {
          ru: 'webinar/first-image-ru.jpg',
          en: 'webinar/first-image-en.jpg',
        },
      },
      {
        type: 'subtitle',
        text: ['news.items.webinar.subtitle'],
      },
      {
        type: 'text',
        text: [
          'news.items.webinar.text.0',
          'news.items.webinar.text.1',
          'news.items.webinar.text.2',
        ],
      },
      {
        type: 'quote',
        text: ['news.items.webinar.review.text'],
        name: 'news.items.webinar.review.name',
        position: 'news.items.webinar.review.place',
        image: 'webinar/talapker.png',
      },
      {
        type: 'text',
        text: ['news.items.webinar.text.3', 'news.items.webinar.text.4'],
      },
      {
        type: 'quote',
        text: ['news.items.webinar.quote.text'],
        name: 'news.items.webinar.quote.name',
        position: 'news.items.webinar.quote.place',
      },
      {
        type: 'text',
        text: ['news.items.webinar.text.5', 'news.items.webinar.text.6'],
      },
    ],
    tournament18: [
      {
        type: 'slider',
        images: [
          'tournament18/main-image-en.jpg',
          'tournament18/slider1-2.jpg',
        ],
      },
      {
        type: 'subtitle',
        textWithLink: [
          [
            {
              text: 'news.items.tournament18.description',
            },
          ],
        ],
      },
      {
        type: 'text',
        textWithLink: [
          [
            {
              text: 'news.items.tournament18.text.0',
            },
            {
              text: 'live.idChess.com',
              link: 'http://live.idChess.com',
            },
          ],
        ],
      },
      {
        type: 'results',
        isRegular: true,
        results: [
          {
            text: 'news.items.tournament18.records.items.0',
            link: 'https://live.ruchess.ru/app/show/tournament/eyJpZCI6MzA2OCwicGFzc3dvcmQiOm51bGx9?tour=eyJpZCI6MTcwMDIsInBhc3N3b3JkIjpudWxsfQ==',
          },
          {
            text: 'news.items.tournament18.records.items.1',
            link: 'https://live.ruchess.ru/app/show/tournament/eyJpZCI6MzA2OSwicGFzc3dvcmQiOm51bGx9?tour=eyJpZCI6MTcwMDMsInBhc3N3b3JkIjpudWxsfQ==',
          },
          {
            text: 'news.items.tournament18.records.items.2',
            link: 'https://live.idchess.com/app/show/tournament/eyJpZCI6MzA3MCwicGFzc3dvcmQiOm51bGx9?tour=eyJpZCI6MTcwMDQsInBhc3N3b3JkIjpudWxsfQ==',
          },
        ],
      },
      {
        type: 'text',
        text: ['news.items.tournament18.text.1'],
      },
      {
        type: 'slider',
        images: [
          'tournament18/slider2-1.jpg',
          'tournament18/slider2-2.jpg',
          'tournament18/slider2-3.jpg',
        ],
      },
      {
        type: 'text',
        text: ['news.items.tournament18.text.2'],
      },
      {
        type: 'slider',
        images: [
          'tournament18/slider3-1.jpg',
          'tournament18/slider3-2.jpg',
          'tournament18/slider3-3.jpg',
        ],
      },
      {
        type: 'text',
        text: ['news.items.tournament18.text.3'],
      },
      {
        type: 'slider',
        images: ['tournament18/slider4-1.jpg', 'tournament18/slider4-2.jpg'],
      },
    ],
    mongolia: [
      {
        type: 'slider',
        images: [
          'mongolia/mongolia-slider1-1.jpg',
          'mongolia/mongolia-slider1-2.jpg',
        ],
      },
      {
        type: 'subtitle',
        text: ['news.items.mongolia.subtitle'],
      },
      {
        type: 'text',
        text: ['news.items.mongolia.text.0', 'news.items.mongolia.text.1'],
      },
      {
        type: 'slider',
        images: [
          'mongolia/mongolia-slider2-1.jpg',
          'mongolia/mongolia-slider2-2.jpg',
          'mongolia/mongolia-slider2-3.jpg',
        ],
      },
      {
        type: 'results',
        title: 'news.items.mongolia.resultText',
        line: true,
        results: [
          {
            text: 'news.items.mongolia.tournament.title1',
            rating: 'news.items.mongolia.tournament.text1',
          },
          {
            text: 'news.items.mongolia.tournament.title2',
            rating: 'news.items.mongolia.tournament.text2',
          },
        ],
      },
      {
        type: 'text',
        text: ['news.items.mongolia.text.2'],
      },
    ],
    iraq_tournament: [
      {
        type: 'image',
        alt: 'news.items.iraq_tournament.alt',
        imageLocale: {
          ru: 'iraq_tournament/1.jpg',
          en: 'iraq_tournament/1.jpg',
        },
      },
      {
        type: 'subtitle',
        text: ['news.items.iraq_tournament.subtitle'],
      },
      {
        type: 'text',
        text: ['news.items.iraq_tournament.text.0'],
      },
      {
        type: 'slider',
        images: ['iraq_tournament/2.jpg', 'iraq_tournament/3.jpg'],
      },
      {
        type: 'text',
        text: [
          'news.items.iraq_tournament.text.1',
          'news.items.iraq_tournament.text.2',
        ],
      },
    ],
    usa_tournaments: [
      {
        type: 'image',
        alt: 'news.items.usa_tournaments.alt',
        imageLocale: {
          ru: 'usa_tournaments/1.jpg',
          en: 'usa_tournaments/1.jpg',
        },
      },
      {
        type: 'subtitle',
        text: ['news.items.usa_tournaments.subtitle'],
      },
      {
        type: 'text',
        text: ['news.items.usa_tournaments.text.0'],
      },
      {
        type: 'slider',
        images: [
          'usa_tournaments/2.jpg',
          'usa_tournaments/3.jpg',
          'usa_tournaments/4.jpg',
        ],
      },
      {
        type: 'text',
        textWithLink: [
          [
            {
              text: 'news.items.usa_tournaments.text.1',
            },
            {
              text: 'live.idChess.com',
              link: 'http://live.idChess.com',
            },
            {
              text: 'news.items.usa_tournaments.text.2',
            },
          ],
        ],
      },
      {
        type: 'results',
        isRegular: true,
        results: [
          {
            text: 'news.items.usa_tournaments.records.items.0',
            link: 'https://live.idchess.com/app/show/tournament/eyJpZCI6MzU0OCwicGFzc3dvcmQiOm51bGx9?tour=eyJpZCI6MTk4NDUsInBhc3N3b3JkIjpudWxsfQ==',
          },
          {
            text: 'news.items.usa_tournaments.records.items.1',
            link: 'https://live.idchess.com/app/show/tournament/eyJpZCI6MzY4OCwicGFzc3dvcmQiOm51bGx9?tour=eyJpZCI6MjA3NDEsInBhc3N3b3JkIjpudWxsfQ==',
          },
        ],
      },
    ],
    azerbaijan_tournaments: [
      {
        type: 'subtitle',
        text: ['news.items.azerbaijan_tournaments.subtitle'],
      },
      {
        type: 'text',
        text: ['news.items.azerbaijan_tournaments.text.0'],
      },
      {
        type: 'image',
        alt: 'news.items.azerbaijan_tournaments.alt1',
        imageLocale: {
          ru: 'azerbaijan_tournaments/1.jpg',
          en: 'azerbaijan_tournaments/1.jpg',
        },
      },
      {
        type: 'quote',
        text: ['news.items.azerbaijan_tournaments.quote.text'],
        name: 'news.items.azerbaijan_tournaments.quote.name',
        position: 'news.items.azerbaijan_tournaments.quote.place',
      },
      {
        type: 'image',
        alt: 'news.items.azerbaijan_tournaments.alt2',
        imageLocale: {
          ru: 'azerbaijan_tournaments/2.jpg',
          en: 'azerbaijan_tournaments/2.jpg',
        },
      },
      {
        type: 'text',
        textWithLink: [
          [
            {
              text: 'news.items.azerbaijan_tournaments.text.1',
            },
            {
              text: 'news.items.azerbaijan_tournaments.text.2',
              link: 'http://live.idchess.com',
            },
            {
              text: 'news.items.azerbaijan_tournaments.text.3',
            },
          ],
        ],
      },
      {
        type: 'image',
        alt: 'news.items.azerbaijan_tournaments.alt3',
        imageLocale: {
          ru: 'azerbaijan_tournaments/3.jpg',
          en: 'azerbaijan_tournaments/3.jpg',
        },
      },
    ],
    serbia: [
      {
        type: 'text',
        text: ['news.items.serbia.text.0'],
      },
      {
        type: 'slider',
        images: ['serbia/serbia-slider-1.jpg', 'serbia/serbia-slider-2.jpg'],
      },
      {
        type: 'text',
        text: ['news.items.serbia.text.1', 'news.items.serbia.text.2'],
      },
      {
        type: 'image',
        image: 'serbia/serbia-results.jpg',
        alt: 'news.items.serbia.title',
      },
      {
        type: 'text',
        text: ['news.items.serbia.text.3', 'news.items.serbia.text.4'],
      },
      {
        type: 'quote',
        text: ['news.items.serbia.quote.text'],
        name: 'news.items.serbia.quote.name',
        position: 'news.items.serbia.quote.place',
      },
    ],
    oman: [
      {
        type: 'image',
        image: 'oman/oman-1.jpg',
        alt: 'news.items.oman.title',
      },
      {
        type: 'text',
        text: ['news.items.oman.text.0'],
      },
      {
        type: 'text',
        textWithLink: [
          [
            {
              text: 'news.items.oman.text.1',
            },
            {
              text: 'news.items.oman.text.2',
              link: 'https://live.idchess.com/app/show/tournament/eyJpZCI6MzUxNiwicGFzc3dvcmQiOm51bGx9?tour=eyJpZCI6MTk5ODksInBhc3N3b3JkIjpudWxsfQ==',
            },
            {
              text: 'news.items.oman.text.3',
            },
          ],
        ],
      },
      {
        type: 'image',
        image: 'oman/oman-2.jpg',
        alt: 'news.items.oman.description',
      },
      {
        type: 'text',
        textWithLink: [
          [
            {
              text: 'news.items.oman.text.4',
            },
            {
              text: 'news.items.oman.text.5',
              link: 'https://live.idchess.com/app/show/tournament/eyJpZCI6Mzg0NSwicGFzc3dvcmQiOm51bGx9?tour=eyJpZCI6MjE5NTUsInBhc3N3b3JkIjpudWxsfQ==',
            },
            {
              text: 'news.items.oman.text.6',
            },
          ],
        ],
      },
      {
        type: 'quote',
        text: ['news.items.oman.quote.text'],
        name: 'news.items.oman.quote.name',
        position: 'news.items.oman.quote.place',
      },
      {
        type: 'text',
        text: ['news.items.oman.text.7'],
      },
    ],
    aeroflot: [
      {
        type: 'slider',
        images: [
          'aeroflot/aeroflot-slider1-1.jpg',
          'aeroflot/aeroflot-slider1-2.jpg',
          'aeroflot/aeroflot-slider1-3.jpg',
        ],
      },
      {
        type: 'subtitle',
        text: ['news.items.aeroflot.subtitle'],
      },
      {
        type: 'text',
        text: ['news.items.aeroflot.text.0'],
      },
      {
        type: 'text',
        text: ['news.items.aeroflot.text.1'],
      },
      {
        type: 'slider',
        images: [
          'aeroflot/aeroflot-slider2-1.jpg',
          'aeroflot/aeroflot-slider2-2.jpg',
          'aeroflot/aeroflot-slider2-3.jpg',
          'aeroflot/aeroflot-slider2-4.jpg',
        ],
      },
      {
        type: 'text',
        text: ['news.items.aeroflot.text.2'],
      },
      {
        type: 'text',
        text: ['news.items.aeroflot.text.3'],
      },
      {
        type: 'subtitle',
        text: ['news.items.aeroflot.results.title'],
      },
      {
        type: 'results',
        isBold: true,
        line: true,
        results: [
          {
            text: 'news.items.aeroflot.results.tournaments.0.name',
            link: '',
            rating: 'news.items.aeroflot.results.tournaments.0.rating',
          },
          {
            text: 'news.items.aeroflot.results.tournaments.1.name',
            link: '',
            rating: 'news.items.aeroflot.results.tournaments.1.rating',
          },
          {
            text: 'news.items.aeroflot.results.tournaments.2.name',
            link: '',
            rating: 'news.items.aeroflot.results.tournaments.2.rating',
          },
          {
            text: 'news.items.aeroflot.results.tournaments.3.name',
            link: '',
            rating: 'news.items.aeroflot.results.tournaments.3.rating',
          },
        ],
      },
      {
        type: 'slider',
        images: [
          'aeroflot/aeroflot-slider3-1.jpg',
          'aeroflot/aeroflot-slider3-2.jpg',
          'aeroflot/aeroflot-slider3-3.jpg',
        ],
      },
      {
        type: 'text',
        text: ['news.items.aeroflot.text.4'],
      },
      {
        type: 'text',
        textWithLink: [
          [
            {
              text: 'news.items.aeroflot.link',
            },
            {
              text: 'live.idchess.com',
              link: 'http://live.idchess.com',
            },
          ],
        ],
      },
    ],
    veb: [
      {
        type: 'text',
        text: ['news.items.veb.text.0'],
      },
      {
        type: 'image',
        image: 'veb/veb-slider1-1.jpeg',
      },
      {
        type: 'subtitle',
        text: ['news.items.veb.subtitle'],
      },
      {
        type: 'text',
        text: ['news.items.veb.text.1'],
      },
      {
        type: 'slider',
        images: [
          'veb/veb-slider2-1.jpeg',
          'veb/veb-slider2-2.jpeg',
          'veb/veb-slider2-3.jpeg',
        ],
      },
      {
        type: 'results',
        isRegular: true,
        results: [
          {
            text: 'news.items.veb.result1Text',
            link: 'https://live.idchess.com/app/show/tournament/eyJpZCI6Mzg5NywicGFzc3dvcmQiOm51bGx9?tour=eyJpZCI6MjE4NzcsInBhc3N3b3JkIjpudWxsfQ==',
          },
        ],
      },
      {
        type: 'subtitle',
        text: ['news.items.veb.text.2'],
      },
      {
        type: 'text',
        textWithLink: [
          [
            {
              text: 'news.items.veb.text.3',
            },
            {
              text: 'live.idchess.com',
              link: 'https://live.idchess.com/app/show/tournament/eyJpZCI6MzkzNCwicGFzc3dvcmQiOm51bGx9?tour=eyJpZCI6MjIwMzgsInBhc3N3b3JkIjpudWxsfQ==',
            },
          ],
        ],
      },
      {
        type: 'slider',
        images: [
          'veb/veb-slider3-1.jpeg',
          'veb/veb-slider3-2.jpeg',
          'veb/veb-slider3-3.jpeg',
        ],
      },
      {
        type: 'results',
        isRegular: true,
        results: [
          {
            text: 'news.items.veb.result2Text',
            link: 'https://live.idchess.com/app/show/tournament/eyJpZCI6MzkzNCwicGFzc3dvcmQiOm51bGx9?tour=eyJpZCI6MjIwMzgsInBhc3N3b3JkIjpudWxsfQ==',
          },
        ],
      },
      {
        type: 'text',
        text: ['news.items.veb.text.4'],
      },
      {
        type: 'image',
        image: 'veb/veb-slider4-1.jpeg',
      },
      {
        type: 'results',
        isRegular: true,
        results: [
          {
            text: 'news.items.veb.result3Text',
            link: 'https://live.idchess.com/app/show/tournament/eyJpZCI6Mzk0NiwicGFzc3dvcmQiOm51bGx9?tour=eyJpZCI6MjIwNTksInBhc3N3b3JkIjpudWxsfQ==',
          },
        ],
      },
      {
        type: 'text',
        textWithLink: [
          [
            {
              text: 'news.items.veb.text.5',
            },
            {
              text: 'live.idchess.com',
              link: 'https://live.idchess.com/app/show/tournament/eyJpZCI6MzkzNCwicGFzc3dvcmQiOm51bGx9?tour=eyJpZCI6MjIwMzgsInBhc3N3b3JkIjpudWxsfQ==',
            },
            {
              text: 'news.items.veb.text.6',
            },
          ],
        ],
      },
    ],
    time_control: [
      {
        type: 'text',
        text: ['news.items.time_control.text.0'],
      },
      {
        type: 'image',
        alt: 'news.items.time_control.title',
        imageLocale: {
          ru: 'time_control/time_control-main-image-ru.jpg',
          en: 'time_control/time_control-main-image-en.jpg',
        },
      },
      {
        type: 'text',
        text: [
          'news.items.time_control.text.1',
          'news.items.time_control.text.2',
          'news.items.time_control.text.3',
          'news.items.time_control.text.4',
          'news.items.time_control.text.5',
          'news.items.time_control.text.6',
        ],
      },
    ],
    against_computer: [
      {
        type: 'text',
        text: ['news.items.against_computer.text.0'],
      },
      {
        type: 'image',
        alt: 'news.items.against_computer.title',
        imageLocale: {
          ru: 'against_computer/against_computer-main-image-ru.jpg',
          en: 'against_computer/against_computer-main-image-en.jpg',
        },
      },
      {
        type: 'subtitle',
        text: ['news.items.against_computer.subtitle'],
      },
      {
        type: 'text',
        text: [
          'news.items.against_computer.text.1',
          'news.items.against_computer.text.2',
          'news.items.against_computer.text.3',
          'news.items.against_computer.text.4',
          'news.items.against_computer.text.5',
        ],
      },
    ],
    moscow: [
      {
        type: 'text',
        text: ['news.items.moscow.text.0'],
      },
      {
        type: 'image',
        image: 'moscow/moscow-main-image.jpg',
        alt: 'news.items.moscow.title',
      },
      {
        type: 'text',
        text: ['news.items.moscow.text.1'],
      },
      {
        type: 'text',
        textWithLink: [
          [
            {
              text: 'news.items.moscow.text.2',
            },
            {
              text: 'news.items.moscow.text.3',
              link: 'https://live.idchess.com/',
            },
            {
              text: 'news.items.moscow.text.4',
            },
          ],
        ],
      },
      {
        type: 'slider',
        images: [
          'moscow/moscow-slider1-1.jpg',
          'moscow/moscow-slider1-2.jpg',
          'moscow/moscow-slider1-3.jpg',
          'moscow/moscow-slider1-4.jpg',
        ],
      },
      {
        type: 'results',
        isBold: true,
        line: true,
        results: [
          {
            text: 'news.items.moscow.results.tournaments.0.name',
            rating: 'news.items.moscow.results.tournaments.0.rating',
          },
          {
            text: 'news.items.moscow.results.tournaments.1.name',
            rating: 'news.items.moscow.results.tournaments.1.rating',
          },
          {
            text: 'news.items.moscow.results.tournaments.2.name',
            rating: 'news.items.moscow.results.tournaments.2.rating',
          },
          {
            text: 'news.items.moscow.results.tournaments.3.name',
            rating: 'news.items.moscow.results.tournaments.3.rating',
          },
        ],
      },
      {
        type: 'slider',
        images: [
          'moscow/moscow-slider2-1.jpg',
          'moscow/moscow-slider2-2.jpg',
          'moscow/moscow-slider2-3.jpg',
        ],
      },
      {
        type: 'results',
        isRegular: true,
        results: [
          {
            text: 'news.items.moscow.text.5',
          },
          {
            text: 'news.items.moscow.resultText',
            link: 'https://live.idchess.com/app/show/tournament/eyJpZCI6NDAxNSwicGFzc3dvcmQiOm51bGx9?tour=eyJpZCI6MjM0NjYsInBhc3N3b3JkIjpudWxsfQ==',
          },
        ],
      },
    ],
    croatia: [
      {
        type: 'image',
        image: 'croatia/croatia-main-photo.jpg',
        alt: 'news.items.croatia.title',
      },
      {
        type: 'subtitle',
        text: ['news.items.croatia.subtitle'],
      },
      {
        type: 'text',
        text: ['news.items.croatia.text.0'],
      },
      {
        type: 'slider',
        images: [
          'croatia/croatia-slider1-1.jpg',
          'croatia/croatia-slider1-2.jpg',
        ],
      },
      {
        type: 'quote',
        text: ['news.items.croatia.reviews.0.text'],
        name: 'news.items.croatia.reviews.0.name',
        position: 'news.items.croatia.reviews.0.position',
      },
      {
        type: 'text',
        text: ['news.items.croatia.text.1'],
      },
      {
        type: 'quote',
        text: ['news.items.croatia.reviews.1.text'],
        name: 'news.items.croatia.reviews.1.name',
        position: 'news.items.croatia.reviews.1.position',
      },
      {
        type: 'slider',
        images: [
          'croatia/croatia-slider2-1.jpg',
          'croatia/croatia-slider2-2.jpg',
        ],
      },
      {
        type: 'text',
        textWithLink: [
          [
            {
              text: 'news.items.croatia.text.2',
            },
            {
              text: 'news.items.croatia.text.3',
              link: 'https://live.idchess.com/app/show/tournament/eyJpZCI6NDExMCwicGFzc3dvcmQiOm51bGx9?tour=eyJpZCI6MjQyMjIsInBhc3N3b3JkIjpudWxsfQ==',
            },
          ],
        ],
      },
      {
        type: 'photosBy',
        text: ['news.items.croatia.photos_by'],
      },
    ],
    everest: [
      {
        type: 'subtitle',
        text: ['news.items.everest.subtitle'],
      },
      {
        type: 'image',
        image: 'everest/everest-main-image.jpg',
        alt: 'news.items.everest.title',
      },
      {
        type: 'text',
        text: ['news.items.everest.text.0'],
      },
      {
        type: 'quote',
        text: ['news.items.everest.reviews.0.text'],
        name: 'news.items.everest.reviews.0.name',
        position: 'news.items.everest.reviews.0.position',
      },
      {
        type: 'slider',
        images: [
          'everest/everest-slider-1.jpg',
          'everest/everest-slider-2.jpg',
          'everest/everest-slider-3.jpg',
        ],
      },
      {
        type: 'quote',
        text: ['news.items.everest.reviews.1.text'],
        name: 'news.items.everest.reviews.1.name',
        position: 'news.items.everest.reviews.1.position',
      },
      {
        type: 'text',
        textWithLink: [
          [
            {
              text: 'news.items.everest.text.1',
            },
            {
              text: 'news.items.everest.text.2',
              link: 'https://live.idchess.com/app/show/tournament/eyJpZCI6NDc2OCwicGFzc3dvcmQiOm51bGx9?tour=eyJpZCI6MjYyMzQsInBhc3N3b3JkIjpudWxsfQ==',
            },
          ],
        ],
      },
      {
        type: 'photosBy',
        text: ['news.items.everest.photos_by'],
      },
    ],
    big_chess: [
      {
        type: 'subtitle',
        text: ['news.items.big_chess.subtitle'],
      },
      {
        type: 'image',
        image: 'big_chess/big_chess-main-image.jpg',
        alt: 'news.items.big_chess.title',
      },
      {
        type: 'text',
        text: ['news.items.big_chess.text.0', 'news.items.big_chess.text.1'],
      },
      {
        type: 'slider',
        images: [
          'big_chess/big_chess-slider1-1.jpg',
          'big_chess/big_chess-slider1-2.jpg',
          'big_chess/big_chess-slider1-3.jpg',
          'big_chess/big_chess-slider1-4.jpg',
        ],
      },
      {
        type: 'quote',
        text: ['news.items.big_chess.quote.text'],
        name: 'news.items.big_chess.quote.name',
        position: 'news.items.big_chess.quote.place',
      },
      {
        type: 'slider',
        images: [
          'big_chess/big_chess-slider2-1.jpg',
          'big_chess/big_chess-slider2-2.jpg',
          'big_chess/big_chess-slider2-3.jpg',
          'big_chess/big_chess-slider2-4.jpg',
        ],
      },
      {
        type: 'text',
        textWithLink: [
          [
            {
              text: 'news.items.big_chess.text.2',
            },
            {
              text: 'news.items.big_chess.text.3',
              link: 'https://live.idchess.com/app/show/tournament/eyJpZCI6NDg5MiwicGFzc3dvcmQiOm51bGx9?tour=eyJpZCI6MjY5NDQsInBhc3N3b3JkIjpudWxsfQ==',
            },
          ],
        ],
      },
      {
        type: 'photosBy',
        text: ['news.items.big_chess.photos_by'],
      },
    ],
    mexico: [
      {
        type: 'subtitle',
        text: ['news.items.mexico.subtitle'],
      },
      {
        type: 'image',
        image: 'mexico/mexico-main-image.jpg',
        alt: 'news.items.mexico.title',
      },
      {
        type: 'text',
        text: ['news.items.mexico.text.0'],
      },
      {
        type: 'slider',
        images: [
          'mexico/mexico-slider1-1.jpg',
          'mexico/mexico-slider1-2.jpg',
          'mexico/mexico-slider1-3.jpg',
        ],
      },
      {
        type: 'quote',
        text: ['news.items.mexico.quote.text'],
        name: 'news.items.mexico.quote.name',
        position: 'news.items.mexico.quote.place',
      },
      {
        type: 'slider',
        images: ['mexico/mexico-slider2-1.jpg', 'mexico/mexico-slider2-2.jpg'],
      },
      {
        type: 'text',
        textWithLink: [
          [
            {
              text: 'news.items.mexico.text.1',
            },
            {
              text: 'news.items.mexico.text.2',
              link: 'https://live.idchess.com/app/show/tournament/eyJpZCI6NDc5MCwicGFzc3dvcmQiOm51bGx9?tour=eyJpZCI6MjY1MDEsInBhc3N3b3JkIjpudWxsfQ==',
            },
            {
              text: 'news.items.mexico.text.3',
            },
          ],
        ],
      },
      {
        type: 'photosBy',
        text: ['news.items.mexico.photos_by'],
      },
    ],
    veterans: [
      {
        type: 'subtitle',
        text: ['news.items.veterans.subtitle'],
      },
      {
        type: 'image',
        image: 'veterans/veterans-main-image.jpg',
        alt: 'news.items.veterans.title',
      },
      {
        type: 'text',
        text: [
          'news.items.veterans.text.0',
          'news.items.veterans.text.1',
          'news.items.veterans.text.2',
        ],
      },
      {
        type: 'slider',
        images: [
          'veterans/veterans-slider1-1.jpg',
          'veterans/veterans-slider1-2.jpg',
          'veterans/veterans-slider1-3.jpg',
        ],
      },
      {
        type: 'text',
        text: ['news.items.veterans.text.3', 'news.items.veterans.text.4'],
      },
      {
        type: 'slider',
        images: [
          'veterans/veterans-slider2-1.jpg',
          'veterans/veterans-slider2-2.jpg',
          'veterans/veterans-slider2-3.jpg',
        ],
      },
      {
        type: 'text',
        text: ['news.items.veterans.text.5'],
      },
      {
        type: 'slider',
        images: [
          'veterans/veterans-slider3-1.jpg',
          'veterans/veterans-slider3-2.jpg',
          'veterans/veterans-slider3-3.jpg',
          'veterans/veterans-slider3-4.jpg',
        ],
      },
      {
        type: 'text',
        textWithLink: [
          [
            {
              text: 'news.items.veterans.text.6',
            },
            {
              text: 'news.items.veterans.text.7',
              link: 'https://live.idchess.com/app/show/tournament/eyJpZCI6NDU3MiwicGFzc3dvcmQiOm51bGx9?tour=eyJpZCI6Mjc0NzQsInBhc3N3b3JkIjpudWxsfQ==',
            },
            {
              text: 'news.items.veterans.text.8',
            },
            {
              text: 'news.items.veterans.text.9',
              link: 'https://live.idchess.com/app/show/tournament/eyJpZCI6NDk2MywicGFzc3dvcmQiOm51bGx9?tour=eyJpZCI6Mjc0NzMsInBhc3N3b3JkIjpudWxsfQ==',
            },
          ],
        ],
      },
      {
        type: 'photosBy',
        text: ['news.items.veterans.photos_by'],
      },
    ],
    four_countries: [
      {
        type: 'subtitle',
        text: ['news.items.four_countries.subtitle.0'],
      },
      {
        type: 'image',
        image: 'four_countries/four_countries-main-image.jpg',
        alt: 'news.items.four_countries.title',
      },
      {
        type: 'subtitle',
        text: ['news.items.four_countries.subtitle.1'],
      },
      {
        type: 'text',
        text: [
          'news.items.four_countries.text.0',
          'news.items.four_countries.text.1',
        ],
      },
      {
        type: 'text',
        textWithLink: [
          [
            {
              text: 'news.items.four_countries.text.2',
            },
            {
              text: 'news.items.four_countries.text.3',
              link: 'https://live.idchess.com/app/show/tournament/eyJpZCI6NDg0OCwicGFzc3dvcmQiOm51bGx9?tour=eyJpZCI6MjcwMjcsInBhc3N3b3JkIjpudWxsfQ==',
            },
          ],
        ],
      },
      {
        type: 'slider',
        images: [
          'four_countries/four_countries-slider1-1.jpg',
          'four_countries/four_countries-slider1-2.jpg',
        ],
      },
      {
        type: 'subtitle',
        text: ['news.items.four_countries.subtitle.2'],
      },
      {
        type: 'text',
        text: [
          'news.items.four_countries.text.4',
          'news.items.four_countries.text.5',
        ],
      },
      {
        type: 'text',
        textWithLink: [
          [
            {
              text: 'news.items.four_countries.text.6',
            },
            {
              text: 'news.items.four_countries.text.7',
              link: 'https://live.idchess.com/app/show/tournament/eyJpZCI6NDg1NCwicGFzc3dvcmQiOm51bGx9?tour=eyJpZCI6MjY5OTgsInBhc3N3b3JkIjpudWxsfQ==',
            },
          ],
        ],
      },
      {
        type: 'slider',
        images: [
          'four_countries/four_countries-slider2-1.jpg',
          'four_countries/four_countries-slider2-2.jpg',
        ],
      },
      {
        type: 'subtitle',
        text: ['news.items.four_countries.subtitle.3'],
      },
      {
        type: 'text',
        text: ['news.items.four_countries.text.8'],
      },
      {
        type: 'text',
        textWithLink: [
          [
            {
              text: 'news.items.four_countries.text.9',
            },
            {
              text: 'news.items.four_countries.text.7',
              link: 'https://live.idchess.com/app/show/tournament/eyJpZCI6NDg2MCwicGFzc3dvcmQiOm51bGx9?tour=eyJpZCI6MjcwNjYsInBhc3N3b3JkIjpudWxsfQ==',
            },
          ],
        ],
      },
      {
        type: 'slider',
        images: [
          'four_countries/four_countries-slider3-1.jpg',
          'four_countries/four_countries-slider3-2.jpg',
        ],
      },
      {
        type: 'subtitle',
        text: ['news.items.four_countries.subtitle.4'],
      },
      {
        type: 'text',
        text: ['news.items.four_countries.text.10'],
      },
      {
        type: 'quote',
        text: ['news.items.four_countries.quote.text'],
        name: 'news.items.four_countries.quote.name',
        position: 'news.items.four_countries.quote.place',
      },
      {
        type: 'text',
        textWithLink: [
          [
            {
              text: 'news.items.four_countries.text.11',
            },
            {
              text: 'news.items.four_countries.text.3',
              link: 'https://live.idchess.com/app/show/tournament/eyJpZCI6NDk0OCwicGFzc3dvcmQiOm51bGx9?tour=eyJpZCI6MjczOTQsInBhc3N3b3JkIjpudWxsfQ==',
            },
          ],
        ],
      },
      {
        type: 'slider',
        images: [
          'four_countries/four_countries-slider4-1.jpg',
          'four_countries/four_countries-slider4-2.jpg',
        ],
      },
      {
        type: 'photosBy',
        text: ['news.items.four_countries.photos_by'],
      },
    ],
  },
  en: {
    moscow: [
      {
        type: 'text',
        text: ['news.items.moscow.text.0'],
      },
      {
        type: 'image',
        image: 'moscow/moscow-main-image.jpg',
        alt: 'news.items.moscow.title',
      },
      {
        type: 'text',
        text: ['news.items.moscow.text.1'],
      },
      {
        type: 'text',
        textWithLink: [
          [
            {
              text: 'news.items.moscow.text.2',
            },
            {
              text: 'news.items.moscow.text.3',
              link: 'https://live.idchess.com/',
            },
            {
              text: 'news.items.moscow.text.4',
            },
          ],
        ],
      },
      {
        type: 'slider',
        images: [
          'moscow/moscow-slider1-1.jpg',
          'moscow/moscow-slider1-2.jpg',
          'moscow/moscow-slider1-3.jpg',
          'moscow/moscow-slider1-4.jpg',
        ],
      },
      {
        type: 'slider',
        images: [
          'moscow/moscow-slider2-1.jpg',
          'moscow/moscow-slider2-2.jpg',
          'moscow/moscow-slider2-3.jpg',
        ],
      },
      {
        type: 'results',
        isRegular: true,
        results: [
          {
            text: 'news.items.moscow.text.5',
            link: 'https://live.idchess.com/app/show/tournament/eyJpZCI6NDAxNSwicGFzc3dvcmQiOm51bGx9?tour=eyJpZCI6MjM0NjYsInBhc3N3b3JkIjpudWxsfQ==',
          },
        ],
      },
    ],
    croatia: [
      {
        type: 'subtitle',
        text: ['news.items.croatia.subtitle'],
      },
      {
        type: 'image',
        image: 'croatia/croatia-main-photo.jpg',
        alt: 'news.items.croatia.title',
      },
      {
        type: 'text',
        text: [
          'news.items.croatia.text.0',
          'news.items.croatia.text.1',
          'news.items.croatia.text.2',
        ],
      },
      {
        type: 'slider',
        images: [
          'croatia/croatia-slider1-1.jpg',
          'croatia/croatia-slider1-2.jpg',
        ],
      },
      {
        type: 'text',
        text: ['news.items.croatia.text.3'],
      },
      {
        type: 'quote',
        text: ['news.items.croatia.reviews.0.text'],
        name: 'news.items.croatia.reviews.0.name',
        position: 'news.items.croatia.reviews.0.position',
      },
      {
        type: 'text',
        text: ['news.items.croatia.text.4'],
      },
      {
        type: 'quote',
        text: ['news.items.croatia.reviews.1.text'],
        name: 'news.items.croatia.reviews.1.name',
        position: 'news.items.croatia.reviews.1.position',
      },
      {
        type: 'text',
        text: ['news.items.croatia.text.5'],
      },
      {
        type: 'slider',
        images: [
          'croatia/croatia-slider2-1.jpg',
          'croatia/croatia-slider2-2.jpg',
        ],
      },
      {
        type: 'results',
        isBold: true,
        results: [
          {
            text: 'news.items.croatia.leaderboard.name',
            rating: 'news.items.croatia.leaderboard.rating',
          },
        ],
      },
      {
        type: 'text',
        textWithLink: [
          [
            {
              text: 'news.items.croatia.text.6',
            },
            {
              text: 'news.items.croatia.text.7',
              link: 'https://live.idchess.com/app/show/tournament/eyJpZCI6NDExMCwicGFzc3dvcmQiOm51bGx9?tour=eyJpZCI6MjQyMjIsInBhc3N3b3JkIjpudWxsfQ==',
            },
          ],
        ],
      },
      {
        type: 'photosBy',
        text: ['news.items.croatia.photos_by'],
      },
    ],
  },
};

export const getNews = id => {
  const currentItem = news.filter(item => item.link === id)[0];

  if (currentItem) {
    const data: NewsItem = {
      ...currentItem,
    };

    return {
      status: 200,
      data: data,
    };
  }

  return {
    status: 404,
  };
};

export const useGetCurrentNews = () => {
  const router = useRouter();

  const { locale: currentLanguage } = router as {
    locale: string;
  };

  const currentNews = useMemo(
    () => news?.filter(item => item.lang?.includes(currentLanguage)),
    [news, currentLanguage]
  );

  const currentNewsContent = useMemo(() => {
    return Object.keys(newsContent.common)
      ?.filter(key => currentNews.map(({ id }) => id).includes(key))
      .reduce((obj, key) => {
        obj[key] =
          newsContent[currentLanguage]?.[key] || newsContent.common[key];
        return obj;
      }, {});
  }, [newsContent, currentNews, currentLanguage]);

  return { news: currentNews, newsContent: currentNewsContent };
};
